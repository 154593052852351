import { Injectable } from '@angular/core';
import { AddressbookRecord, AddressbookService, RecordsToDisplayModel } from '../../core/services';
import { PaginatedResponse, PaginationModel } from '../../shared/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class ContactUtilsService {

  constructor(
    private addressbookService: AddressbookService
  ) {}

  public filterRecords(records: RecordsToDisplayModel, searchValue: string, paginationData: PaginatedResponse): RecordsToDisplayModel {
    records.filtered = [];
    if(searchValue !== '') {
      records.filtered = records.all.filter((el: AddressbookRecord) => {
        return `${el.firstName} ${el.lastName}`.toLowerCase().includes(searchValue) || el.defaultNumber?.number?.includes(searchValue);
      });
    } else {
      records.filtered = records.all;
    }
    this.calculatePaginationData(records.filtered, paginationData);
    const sortedRecords = this.addressbookService.sortRecordsByType(records.filtered);
    records.displayed = sortedRecords.slice(0, paginationData.pageable.page_size);
    return { ...records, filtered: records.filtered, displayed: records.displayed };
  }

  private calculatePaginationData(records: AddressbookRecord[], paginationData: PaginatedResponse): void{
    paginationData.number_of_elements = records.length;
    paginationData.pageable.page_number = 0;
    paginationData.pageable.offset = 0;
    paginationData.total_pages = Math.ceil(records.length / paginationData.pageable.page_size);
    paginationData.empty = records.length < 1;
    paginationData.first = true;
    paginationData.last = paginationData.pageable.page_size >= records.length;
    paginationData.total_elements = records.length;
  }
}