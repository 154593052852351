import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, take } from 'rxjs/operators';
import { ADDRESSBOOK_TYPES, AddressbookRecord, AddressbookRecordPhoneLabel, AddressbookService, APIAddressbookFilter, APIAddressbookRecord, APIAddressbookRecordPhone, APIAddressbookSearchProperties, CallHistoryRecord, DatabaseService, PaginatedAPIAddressbookContacts } from '../../../core/services';
import { AccountFacade } from '../../../account/store/facade';
import { SessionDTO } from '../../../account/store/states-models';
import { ContactUtilsService } from '../../../contacts/services/contact-utils.service';
import { PaginatedResponse } from '../../models/pagination.model';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../../core/services/notifications';

const SEARCH_PROPERTIES: APIAddressbookSearchProperties[] = [
  APIAddressbookSearchProperties.FIRST_NAME,
  APIAddressbookSearchProperties.LAST_NAME,
  APIAddressbookSearchProperties.PHONE_NUMBER
]

const PAGE_SIZE = 100;

const initialRecord = {
  type: null,
  firstName: null,
  defaultNumber: null,
  numbers: []
}

@Component({
  selector: 'add-to-existing-contact-modal',
  templateUrl: './add-to-existing-contact-modal.component.html',
  styleUrls: ['./add-to-existing-contact-modal.component.scss']
})
export class AddToExistingContactModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    private addressbookService: AddressbookService,
    private accountFacade: AccountFacade,
    private dbService: DatabaseService,
    private contactUtils: ContactUtilsService,
    private notificationService: NotificationService,
    private databaseService: DatabaseService
  ){}
  public searchValue: FormControl<string> = new FormControl<string>('');
  public loading: boolean;
  private filters: APIAddressbookFilter;
  public filteredContacts: AddressbookRecord[];
  private allContacts: AddressbookRecord[];
  public session: SessionDTO;
  public records: {
    all: AddressbookRecord[],
    filtered: AddressbookRecord[],
    displayed: AddressbookRecord[]
  } = {
    all: [],
    filtered: [],
    displayed: []
  }
  public paginationData: PaginatedResponse;
  public record: AddressbookRecord;
  public callhistoryRecord: CallHistoryRecord;
  public selectedRecord: AddressbookRecord = initialRecord;
  public modalStep: 'selectContact' | 'fillForm';
  public newContact: string;

  ngOnInit(): void {
    this.modalStep = 'selectContact';
    this.accountFacade.session$.subscribe((session: SessionDTO) => {
      this.session = session
    })
    this.searchValue.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.filterContacts();
      this.selectedRecord = initialRecord;
    });
  }

  private generatePagination() {
    this.paginationData = {
      empty: this.records.filtered.length === 0,
      first: true,
      last: this.records.filtered.length - PAGE_SIZE < 1,
      number_of_elements: this.records.displayed.length,
      number: 0,
      size: PAGE_SIZE,
      total_elements: this.records.filtered.length,
      total_pages: this.records.filtered.length % PAGE_SIZE === 0 ? Math.floor(this.records.filtered.length / PAGE_SIZE) : Math.floor((this.records.filtered.length / PAGE_SIZE) + 1),
      sort: {
        empty: this.records.filtered.length === 0,
        sorted: false,
        unsorted: true
      },
      pageable: {
        offset: 0,
        page_number: 0,
        page_size: PAGE_SIZE,
        paged: true,
        unpaged: false,
        sort: {
          empty: this.records.filtered.length === 0,
          sorted: false,
          unsorted: true
        }
      }
    }
  }

  public filterContacts() {
    this.loading = true;
    if (this.searchValue.value) {
      this.filters = {
        input: this.searchValue.value,
        properties: SEARCH_PROPERTIES
      }

      forkJoin({
        locals: this.dbService.getAllAddressbookByType(ADDRESSBOOK_TYPES.LOCAL),
        externals: this.addressbookService.getContacts(this.session.company_id, this.filters, 0, PAGE_SIZE)
      }).subscribe((result: {
        locals: AddressbookRecord[],
        externals: PaginatedAPIAddressbookContacts
      }) => {
        this.records.all = this.records.filtered = result.locals;
        this.records.displayed = this.records.filtered.slice(0, PAGE_SIZE);
        this.generatePagination();
        const filteredLocals = this.contactUtils.filterRecords(this.records, this.searchValue.value, this.paginationData);
        const filteredExternals = result.externals.content.map(contact => {
          return this.addressbookService.fromAPIAddressbookRecord(contact)
        });

        this.filteredContacts = [...filteredLocals.filtered, ...filteredExternals];
        this.loading = false;
      }, () => {
        this.loading = false;
        this.filteredContacts = [];
      })
    } else {
      this.loading = false;
      this.filteredContacts = [];
    }
  }

  public switchSteps(step: "selectContact" | "fillForm") {
    this.modalStep = step;
    if(step === "fillForm") {
      this.selectedRecord.numbers.push({
        label: AddressbookRecordPhoneLabel.SECONDARY,
        number: this.callhistoryRecord?.displayName.number
      })
      this.newContact = this.callhistoryRecord?.displayName.number;
    } else {
      this.selectedRecord.numbers.pop();
    }
  }

  public selectRecord(record: AddressbookRecord) {
    this.selectedRecord = record;
  }

  public onSubmit(data) {
    this.selectedRecord.type === ADDRESSBOOK_TYPES.EXTERNAL ? this.updateExtistingRemoteContact(data.formValue) : this.updateExistingLocalContact(data.formValue);
  }

  private updateExistingLocalContact(formValue): void {
    const hasPhones = formValue.numbers.some(n => n.number);
    const hasEmails = formValue.emails.some(e => e.email);
    let contact: AddressbookRecord = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      company_name: formValue.company_name,
      job_title: formValue.job_title,
      website: formValue.website,
      address: {
        street: formValue.street,
        postal_code: formValue.postal_code,
        city: formValue.city,
        country: formValue.country,
        province: formValue.province,
        address_line: formValue.address_line
      },
      id: this.selectedRecord.id,
      favorite: this.selectedRecord.favorite,
      numbers: hasPhones ? formValue.numbers : [],
      defaultNumber: hasPhones ? formValue.numbers[0] : null,
      emails: hasEmails ? formValue.emails : [],
      type: ADDRESSBOOK_TYPES.LOCAL
    }
    this.databaseService.updateAddressbookRecord(contact).subscribe(() => {
      this.notificationService.success('feature-menu.call-view.add-edit-contact.success-edit');
      this.addressbookService.newContactAdded$.next(null);
      this.close(true);
    });
  }

  private updateExtistingRemoteContact(formValue): void {
    this.accountFacade.session$.pipe(take(1)).subscribe((session: SessionDTO) => {
      const hasPhones = formValue.numbers.some(n => n.number);
      const hasEmails = formValue.emails.some(e => e.email);
      let contact: APIAddressbookRecord = {
        id: this.selectedRecord.id,
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        phones: hasPhones ? <APIAddressbookRecordPhone[]>(formValue.numbers) : null,
        addresses: [
          {
            id: formValue.id,
            country: formValue.country.toLowerCase(),
            city: formValue.city,
            postal_code: formValue.postal_code,
            province: formValue.province,
            street: formValue.street,
            address_line: formValue.address_line
          }
        ],
        emails: hasEmails ? formValue.emails : [],
        job_title: formValue.job_title,
        company_name: formValue.company_name,
        website: formValue.website
      };
      Object.keys(contact).forEach((key) => {
        if(contact[key] === null) delete contact[key]
      })
      this.addressbookService.updateContact(session.company_id, session.user_id, contact.id, contact).subscribe((response: APIAddressbookRecord) => {
        this.notificationService.success('feature-menu.call-view.add-edit-contact.success-edit');
        this.addressbookService.newContactAdded$.next(null);
        this.close(true);
      }, (error) => {
        this.notificationService.error('feature-menu.call-view.add-edit-contact.failure-edit');
      });
    });
  }

  public close(saved: boolean = false): void {
    this.activeModal.close(saved);
  }
}
